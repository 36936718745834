import mutations from "./mutations";
import actions from './actions';
import getters from './getters';

export default{
    namespaced: true,
    state(){
        return {
            cars: {
                ecars:[],
                objects:[]
            },
            pictures:[],
            callTime: null
        }
    },
    mutations,
    actions,
    getters
}