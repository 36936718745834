export default{
    setCars(state,payload){
        state.cars = payload;
    },
    setTime(state,payload){
        state.callTime = payload;
    },
    setPictures(state, payload){
        state.pictures = payload;
    }
}