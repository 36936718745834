import {createStore} from 'vuex'

import AuthModule from './modules/auth';
import CarModule from './modules/car';

export default createStore({
    modules: {
        auth: AuthModule,
        car: CarModule
    }
})
