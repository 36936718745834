<template>
  <div class="gallery">
    <button class="button gallery__close" @click="$emit('on-close')">X</button>
    <div class="gallery__container">
      <div class="gallery__item"
           :class="{'gallery__item--active':current===index,
            'gallery__item--prev':current>index,
            'gallery__item--next':current<index}"
           v-for="(item, index) in images" :key="item.ID">
        <img class="gallery__item__image" :src="`https://www.billo.be/schadewagens/fotos/${item.FOTO}`"
             :alt="`Wagen ${item.WAGENID} - Positie ${item.POSITIE}`"/>
        <div class="gallery__item__caption">Wagen {{ item.WAGENID }} - {{ item.NAAMCHAUFFEUR }} - Positie:
          {{ item.POSITIE }} ({{item.DATUM}})
        </div>
      </div>
      <div class="gallery__control gallery__control--prev" v-if="current !== 0">
        <button class="button" @click="prevSlide">
          PREV
        </button>
      </div>

      <div class="gallery__control gallery__control--next" v-if="current < images.length - 1">
        <button class="button" @click="nextSlide">
          NEXT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: {
    images: {required: true}
  },
  data(){
    return {
      current: 0
    }
  },
  methods: {
    nextSlide() {
      if (this.current === this.images.length - 1) {
        return;
      }
      this.rotation = 0;
      this.current++;
    },
    prevSlide() {
      if (this.current === 0) {
        return;
      }
      this.rotation = 0;
      this.current--;
    },
  }
}
</script>

<style lang="scss" scoped>
.gallery {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9;
}

.gallery__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  z-index: 4;
}

.gallery__container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery__item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: left 0.2s;
}

.gallery__item--active{
  left: 0;
}
.gallery__item--prev{
  left: -100%;
}
.gallery__item--next{
  left: 100%;
}

.gallery__item__image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: center center;
  transform: rotate(0deg);
  transition: all 0.2s;
}

.gallery__item__caption {
  position: absolute;
  width: 100%;
  color: white;
  font-weight: bold;
  z-index: 3;
  bottom: 0;
  text-align: center;
  background: #E5222F;
  padding: 1rem;
}
.gallery__control{
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.gallery__control--next{
  right: 2rem;
}
.gallery__control--prev{
  left: 2rem;
}
</style>