export default{
    cars(state){
        return state.cars;
    },

    ecars(state){
        return state.cars.ecars;
    },

    objects(state){
        return state.cars.objects;
    },

    cardata(state){
        return state.cars.ecars.map(t1 => ({...t1, ...state.cars.objects.find(t2 => t2.objectuid === t1.objectuid)}))
            .sort((a,b) => {
                const aNum = (a.objectno || '').match(/\d+/g)?.[0] || 0;
                const bNum = (b.objectno || '').match(/\d+/g)?.[0] || 0;
                return aNum - bNum;
            });
    },

    callTime(state){
        return state.callTime;
    },

    pictures(state){
        return state.pictures;
    }
}