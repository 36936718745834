<template>
  <div class="page">
    <h1>E-wagenpark</h1>
    <h2>Statusoverzicht - {{ callTime ? callFormatted : "onbekend" }}</h2>
    <div class="page__actions">
      <button class="button button--secondary" @click="picturesActive=!picturesActive">{{picturesActive?"Toon status wagens":"Toon foto lijst"}}</button>
      <template v-if="picturesActive">
        <input type="date" class="input" v-model="pictureFilter.datum" @change="getPictures">
      </template>
      <template v-else>
      <p>Aan het opladen: <b>{{ chargeCount }}</b> ({{ chargeCountGent }} in Depot Gent)</p>
      <button class="button" :class="{'button--active':activeFilter.company==='V-tax'}"
              @click="activeFilter.company=activeFilter.company==='V-tax'?'':'V-tax'">Filter
        V-Tax
      </button>
      <button class="button" :class="{'button--active':activeFilter.company==='HES'}"
              @click="activeFilter.company=activeFilter.company==='HES'?'':'HES'">Filter HES
      </button>
      <button class="button" :class="{'button--active':activeFilter.low}"
              @click="activeFilter.low=!activeFilter.low">Filter
        Low battery
      </button>
      <button class="button" :class="{'button--active':activeFilter.charging}"
              @click="activeFilter.charging=!activeFilter.charging">Filter
        Charging
      </button>
      <button class="button" :class="{'button--active':activeFilter.ignition==='ON'}" @click="activeFilter.ignition=activeFilter.ignition==='ON'?'':'ON'">Filter
        AAN
      </button>
      <button class="button" :class="{'button--active':activeFilter.ignition==='OFF'}" @click="activeFilter.ignition=activeFilter.ignition==='OFF'?'':'OFF'">
        Filter UIT
      </button>
      <button class="button button--secondary" @click="resetFilter">Reset Filters</button>
      </template>
    </div>
    <div class="page__content">
      <template v-if="picturesActive">
        <p v-if="loading">Aan het laden...</p>
        <div v-else-if="pictures && pictures.length" class="cars">
          <table>
            <tr>
              <th>Pers_id</th>
              <th>Chauffeur</th>
              <th>Start</th>
              <th>Finish</th>
              <th>Ritten #</th>
              <th>Status</th>
              <th>Foto's</th>
            </tr>
            <tr :class="{'error':item.status!=='OK'}" v-for="(item,index) in pictures" :key="`pic--${index}`">
              <td>{{item.pers_id}}</td>
              <td>{{item.naam}}</td>
              <td>{{item.start}}</td>
              <td>{{item.finish}}</td>
              <td>{{item.ritten}}</td>
              <td>{{item.status}}</td>
              <td><button class="button button--secondary" @click="galleryItems=item.images" :disabled="!item.images.length">Bekijken</button></td>
            </tr>
          </table>
        </div>
      </template>
      <template v-else>
      <p v-if="loading">Aan het laden...</p>
      <div v-else-if="filteredCarData && filteredCarData.length" class="cars">
        <table>
          <tr>
            <th>Voertuig</th>
            <th>Nummerplaat</th>
            <th>Beschrijving</th>
            <th>Batterij</th>
            <th>Laatste voertuig signaal</th>
            <th>Bereik</th>
            <th>Laatste positie</th>
            <th>Laatste update positie</th>
            <th>Status contact</th>
          </tr>
          <tr class="cars__car" v-for="(item,index) in filteredCarData" :key="`car--${item.objectuid}`">
            <td>{{ item.objectno }}</td>
            <td>{{ item.objectname }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.batteryLevel }}%
              <span v-if="item.isCharging" class="material-icons">electrical_services</span><br>
              <Battery :uid="index" :percentage="item.batteryLevel" :isCharging="item.isCharging"></Battery>
              <span v-if="item.isCharging && item.batteryLevel < 100">100% - <b>{{getSimulation(item)}}</b></span>
            </td>
            <td><span v-if="item.lastModified">{{ item.lastModified.slice(-5) }}<br>{{
                item.lastModified.substr(0, 10)
              }}</span></td>
            <td>{{ item.range / 1000 }} km</td>
            <td v-html="item.postext.replaceAll(',','<br>')"></td>
            <td><span v-if="item.pos_time">{{ item.pos_time.slice(-5) }}<br>{{ item.pos_time.substr(0, 10) }}</span>
            </td>
            <td><b>{{ item.ignition ? 'AAN' : 'UIT' }}</b></td>
          </tr>
        </table>
      </div>
      <p v-else>Geen resultaten op basis van de opgegeven filters.</p>
      </template>
    </div>
    <teleport to="#app">
      <Gallery :images="galleryItems" v-if="galleryItems" @on-close="galleryItems=null"/>
    </teleport>
  </div>
</template>

<script>

import Battery from "./components/Battery";
import Gallery from "./components/Gallery";

export default {
  name: 'App',
  components: {
    Battery,
    Gallery
  },

  data() {
    return {
      loading: false,
      picturesActive: false,
      activeFilter: {
        company: '',
        charging: false,
        low: false,
        ignition: ''
      },
      pictureFilter: {
        datum: ""
      },
      galleryItems: null,
    };
  },

  computed: {
    cardata() {
      return this.$store.getters['car/cardata'];
    },
    callTime() {
      return this.$store.getters['car/callTime'];
    },

    pictures(){
      return this.$store.getters['car/pictures'];
    },

    filteredCarData() {
      let cars = [...this.cardata];
      if (this.activeFilter.company === 'HES') {
        cars = cars.filter(c => c.objectno.toLowerCase()[0] !== 'v');
      } else if (this.activeFilter.company === 'V-tax') {
        cars = cars.filter(c => c.objectno.toLowerCase()[0] === 'v');
      }
      if (this.activeFilter.charging) {
        cars = cars.filter(c => c.isCharging);
      }
      if (this.activeFilter.ignition === 'ON') {
        cars = cars.filter(c => c.ignition);
      } else if (this.activeFilter.ignition === 'OFF') {
        cars = cars.filter(c => !c.ignition);
      }
      if(this.activeFilter.low){
        cars = cars.filter(c => parseInt(c.batteryLevel)<= 30);
      }
      return cars;
    },

    chargeCount() {
      return this.cardata.reduce((a, b) => a + b.isCharging, 0);
    },
    chargeCountGent() {
      return this.cardata.reduce((a, b) => a + (b.isCharging && b.postext.toLowerCase().match('depot gent') ? 1 : 0), 0);
    },

    callFormatted() {
      if (!this.callTime) {
        return null;
      }
      const date = new Date(this.callTime);
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      let h = date.getHours();
      let M = date.getMinutes();

      d = d < 10 ? `0${d}` : d;
      m = m < 10 ? `0${m}` : m;
      h = h < 10 ? `0${h}` : h;
      M = M < 10 ? `0${M}` : M;

      return `${h}:${M} ${d}/${m}/${y}`;
    }
  },

  watch:{
    picturesActive(){
      if(this.picturesActive){
        this.getPictures();
      }
    }
  },

  created() {
    const today = new Date();
    const tDate = `${today.getFullYear()}-${today.toLocaleDateString('nl-BE',{month:'2-digit'})}-${today.toLocaleDateString('nl-BE',{day:'2-digit'})}`;
    this.pictureFilter.datum = tDate;
    this.getToken();
  },

  methods: {
    async getToken() {
      this.loading = true;
      try {
        await this.$store.dispatch('getToken');
        await Promise.all([
            this.getCars(),
            this.getPictures()
        ]);
      } catch (e) {
        alert(e);
      }
      this.loading = false;
    },
    async getCars() {
      try {
        await this.$store.dispatch('car/getCars');
      } catch (e) {
        alert(e);
      }
    },
    async getPictures(){
      this.loading = true;
      try{
        const datum = this.pictureFilter.datum.replaceAll('-','%2F');
        await this.$store.dispatch('car/getPictures', {datum})
      } catch(e) {
        alert(e);
      }
      this.loading = false;
    },

    resetFilter() {
      this.activeFilter = {
        company: '',
        charging: false,
        low: false,
        ignition: ''
      };
    },
    getSimulation({description,lastModified,batteryLevel, postext}){
      let loadTime = 0;
      if(this.checkmatch(postext, 'depot gent')){
        if(this.checkmatch(description, 'nissan')){
          loadTime = 8;
        }
        else if(this.checkmatch(description, 'mustang')){
          loadTime = 15;
        }
        else{
          return 'onbekend';
        }
      }
      else{
        return 'onbekend';
      }
      let dateParts = lastModified.split("/");
      let timeParts = dateParts[2].split(" ")[1].split(":");
      dateParts[2] = dateParts[2].split(" ")[0];
      const lastUpdate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0], timeParts[0], timeParts[1]);

      const loadFactor = loadTime * (100 - batteryLevel)/100;
      const fullTime = new Date(lastUpdate.setTime(lastUpdate.getTime() + loadFactor * 60 * 60 *1000));

      //const date = fullTime.getDate();
      //const month = fullTime.getMonth() + 1;
      //const year = fullTime.getFullYear();
      const hours = fullTime.getHours();
      const minutes = fullTime.getMinutes();
      //return `${date<10?`0${date}`:date}/${month<10?`0${month}`:month}/${year} ${hours<10?`0${hours}`:hours}:${minutes<10?`0${minutes}`:minutes}`;
      return `${hours<10?`0${hours}`:hours}:${minutes<10?`0${minutes}`:minutes}`;

    },
    checkmatch(parent, child){
      return parent.toLowerCase().match(child.toLowerCase());
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/app';
</style>
