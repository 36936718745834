export default {
    async getCars(context) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tomtom/ecars`;
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        if (!response.ok) {
            context.commit('setCars',{ecars:[],objects:[]});
            const error = new Error(
                responseData.error_description || 'Failed to fetch status ecars.'
            );
            throw error;
        }

        if(!responseData.data.objects.errorCode){
            const callTime = Date.now();
            context.commit('setTime', callTime);
            context.commit('setCars', responseData.data);
        }
        else{
            alert('Quota reached (can only access this page 6 times/minute overall)');
        }

        setTimeout(()=>{
            context.dispatch('getCars');
        }, 5 * 60 * 1000)
    },

    async getPictures(context, {datum}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/controlefotoswagens?datum=${datum}`;
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            context.commit('setPictures', []);
            const error = new Error(
                responseData.error_description || 'Failed to fetch pictures ecars.'
            );
            throw error;
        }
        context.commit('setPictures', responseData.data);
    }
}