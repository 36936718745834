<template>
  <div class="battery" :id="`bat--${uid}`" :class="{'battery--charging':isCharging}">
    <div class="battery__progress" :id="`prog--${uid}`">

    </div>
  </div>
</template>

<script>
export default {
  name: "Battery",
  props:{
    uid:{required:true},
    percentage:{required:true},
    isCharging:{}
  },

  mounted(){
    this.setWidth();
  },

  watch:{
    percentage(){
      this.setWidth();
    }
  },

  methods:{
    setWidth(){
      const progress = document.getElementById(`prog--${this.uid}`);
      if(progress) {
        progress.style.width = `${this.percentage}%`;
      }
    }
  }
}
</script>