export default{
    async getToken(context) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_AUTH}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'client_credentials',
                scope: 'openid',
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.error_description || 'Failed to authenticate. Check your client data.'
            );
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;

        setTimeout(function () {
            context.dispatch('getToken');
        }, expiresIn);

        context.commit('setToken', responseData.access_token);
    },
}